import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Sort: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_3772_55742)">
			<rect x="3" y="5" width="18" height="3" rx="1.5" fill="currentColor" />
			<rect x="3" y="11" width="12" height="3" rx="1.5" fill="currentColor" />
			<rect x="3" y="17" width="6" height="3" rx="1.5" fill="currentColor" />
		</g>
		<defs>
			<clipPath id="clip0_3772_55742">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
