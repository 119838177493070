import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const NotificationBell: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M3.42117 16.0473L4.19538 16.6802L3.42117 16.0473ZM20.5788 16.0473L21.353 15.4144L20.5788 16.0473ZM17.9159 11.3185L16.9221 11.43L17.9159 11.3185ZM19.2279 18H4.77207V20H19.2279V18ZM7.07791 11.43L7.20621 10.2862L5.21867 10.0632L5.09037 11.207L7.07791 11.43ZM16.7938 10.2862L16.9221 11.43L18.9096 11.2071L18.7813 10.0632L16.7938 10.2862ZM18.4191 14.9854L19.8046 16.6802L21.353 15.4144L19.9675 13.7196L18.4191 14.9854ZM4.19538 16.6802L5.58091 14.9854L4.03249 13.7196L2.64696 15.4144L4.19538 16.6802ZM4 17.2279C4 17.0283 4.06903 16.8348 4.19538 16.6802L2.64696 15.4144C2.22857 15.9262 2 16.5669 2 17.2279H4ZM19.8046 16.6802C19.931 16.8348 20 17.0283 20 17.2279H22C22 16.5669 21.7714 15.9262 21.353 15.4144L19.8046 16.6802ZM16.9221 11.43C17.0684 12.7345 17.5882 13.9691 18.4191 14.9854L19.9675 13.7196C19.3804 13.0014 19.013 12.1289 18.9096 11.2071L16.9221 11.43ZM5.09037 11.207C4.98697 12.1289 4.61964 13.0014 4.03249 13.7196L5.58091 14.9854C6.41178 13.9691 6.93158 12.7345 7.07791 11.43L5.09037 11.207ZM4.77207 18C4.34567 18 4 17.6543 4 17.2279H2C2 18.7589 3.2411 20 4.77207 20V18ZM19.2279 20C20.7589 20 22 18.7589 22 17.2279H20C20 17.6543 19.6543 18 19.2279 18V20ZM12 6C14.4561 6 16.52 7.84539 16.7938 10.2862L18.7813 10.0632C18.3941 6.6105 15.4744 4 12 4V6ZM12 4C8.52562 4 5.60594 6.6105 5.21867 10.0632L7.20621 10.2862C7.47998 7.84539 9.54393 6 12 6V4Z"
			fill="currentColor"
		/>
		<path d="M15 19C15 20.6569 13.6569 22 12 22C10.3431 22 9 20.6569 9 19" stroke="currentColor" strokeWidth="2" />
		<path d="M14 5V4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4V5" stroke="currentColor" strokeWidth="2" />
	</svg>
);
