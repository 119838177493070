import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const History: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M11.44 2C8.87638 2.00731 6.41349 2.99891 4.56 4.77V3C4.56 2.73478 4.45464 2.48043 4.2671 2.29289C4.07957 2.10536 3.82521 2 3.56 2C3.29478 2 3.04043 2.10536 2.85289 2.29289C2.66535 2.48043 2.56 2.73478 2.56 3V7.5C2.56 7.76522 2.66535 8.01957 2.85289 8.20711C3.04043 8.39464 3.29478 8.5 3.56 8.5H8.06C8.32521 8.5 8.57957 8.39464 8.7671 8.20711C8.95464 8.01957 9.06 7.76522 9.06 7.5C9.06 7.23478 8.95464 6.98043 8.7671 6.79289C8.57957 6.60536 8.32521 6.5 8.06 6.5H5.66C6.57171 5.53701 7.7085 4.81576 8.96813 4.40114C10.2277 3.98651 11.5707 3.89152 12.8761 4.1247C14.1816 4.35788 15.4086 4.91193 16.4468 5.73699C17.485 6.56205 18.3018 7.63226 18.8237 8.85133C19.3457 10.0704 19.5564 11.4001 19.4369 12.7208C19.3174 14.0415 18.8715 15.3118 18.1392 16.4174C17.407 17.5231 16.4114 18.4293 15.242 19.0547C14.0726 19.68 12.7661 20.0049 11.44 20C11.1748 20 10.9204 20.1054 10.7329 20.2929C10.5454 20.4804 10.44 20.7348 10.44 21C10.44 21.2652 10.5454 21.5196 10.7329 21.7071C10.9204 21.8946 11.1748 22 11.44 22C14.0922 22 16.6357 20.9464 18.5111 19.0711C20.3864 17.1957 21.44 14.6522 21.44 12C21.44 9.34784 20.3864 6.8043 18.5111 4.92893C16.6357 3.05357 14.0922 2 11.44 2ZM11.44 8C11.1748 8 10.9204 8.10536 10.7329 8.29289C10.5454 8.48043 10.44 8.73478 10.44 9V12C10.44 12.2652 10.5454 12.5196 10.7329 12.7071C10.9204 12.8946 11.1748 13 11.44 13H13.44C13.7052 13 13.9596 12.8946 14.1471 12.7071C14.3346 12.5196 14.44 12.2652 14.44 12C14.44 11.7348 14.3346 11.4804 14.1471 11.2929C13.9596 11.1054 13.7052 11 13.44 11H12.44V9C12.44 8.73478 12.3346 8.48043 12.1471 8.29289C11.9596 8.10536 11.7052 8 11.44 8Z"
			fill="currentColor"
		/>
	</svg>
);
