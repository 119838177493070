/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Copy: SvgComponent = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M14 5.96016C13.9931 5.89892 13.9796 5.83858 13.96 5.78016V5.72016C13.9279 5.65162 13.8852 5.58861 13.8333 5.5335L9.83333 1.5335C9.77822 1.48164 9.71521 1.43888 9.64667 1.40683C9.62677 1.404 9.60657 1.404 9.58667 1.40683C9.51894 1.36799 9.44415 1.34306 9.36667 1.3335H6.66667C6.13623 1.3335 5.62753 1.54421 5.25245 1.91928C4.87738 2.29436 4.66667 2.80306 4.66667 3.3335V4.00016H4C3.46957 4.00016 2.96086 4.21088 2.58579 4.58595C2.21071 4.96102 2 5.46973 2 6.00016V12.6668C2 13.1973 2.21071 13.706 2.58579 14.081C2.96086 14.4561 3.46957 14.6668 4 14.6668H9.33333C9.86377 14.6668 10.3725 14.4561 10.7475 14.081C11.1226 13.706 11.3333 13.1973 11.3333 12.6668V12.0002H12C12.5304 12.0002 13.0391 11.7894 13.4142 11.4144C13.7893 11.0393 14 10.5306 14 10.0002V6.00016C14 6.00016 14 6.00016 14 5.96016ZM10 3.60683L11.7267 5.3335H10.6667C10.4899 5.3335 10.3203 5.26326 10.1953 5.13823C10.0702 5.01321 10 4.84364 10 4.66683V3.60683ZM10 12.6668C10 12.8436 9.92976 13.0132 9.80474 13.1382C9.67971 13.2633 9.51014 13.3335 9.33333 13.3335H4C3.82319 13.3335 3.65362 13.2633 3.5286 13.1382C3.40357 13.0132 3.33333 12.8436 3.33333 12.6668V6.00016C3.33333 5.82335 3.40357 5.65378 3.5286 5.52876C3.65362 5.40373 3.82319 5.3335 4 5.3335H4.66667V10.0002C4.66667 10.5306 4.87738 11.0393 5.25245 11.4144C5.62753 11.7894 6.13623 12.0002 6.66667 12.0002H10V12.6668ZM12.6667 10.0002C12.6667 10.177 12.5964 10.3465 12.4714 10.4716C12.3464 10.5966 12.1768 10.6668 12 10.6668H6.66667C6.48986 10.6668 6.32029 10.5966 6.19526 10.4716C6.07024 10.3465 6 10.177 6 10.0002V3.3335C6 3.15669 6.07024 2.98712 6.19526 2.86209C6.32029 2.73707 6.48986 2.66683 6.66667 2.66683H8.66667V4.66683C8.66667 5.19726 8.87738 5.70597 9.25245 6.08104C9.62753 6.45612 10.1362 6.66683 10.6667 6.66683H12.6667V10.0002Z"
			fill="currentColor"
		/>
	</svg>
);
