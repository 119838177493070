import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Info: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.25 11.25C10.25 10.6977 10.6977 10.25 11.25 10.25H12C12.5523 10.25 13 10.6977 13 11.25V15.5315C13.4313 15.6425 13.75 16.034 13.75 16.5C13.75 17.0523 13.3023 17.5 12.75 17.5H12C11.4477 17.5 11 17.0523 11 16.5V12.2185C10.5687 12.1075 10.25 11.716 10.25 11.25Z"
			fill="currentColor"
		/>
		<path
			d="M11.8125 9C12.4338 9 12.9375 8.49632 12.9375 7.875C12.9375 7.25368 12.4338 6.75 11.8125 6.75C11.1912 6.75 10.6875 7.25368 10.6875 7.875C10.6875 8.49632 11.1912 9 11.8125 9Z"
			fill="currentColor"
		/>
	</svg>
);
