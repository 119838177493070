import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Printer: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 3.75C5 3.19772 5.44772 2.75 6 2.75H18C18.5523 2.75 19 3.19772 19 3.75V7.5C19 8.05228 18.5523 8.5 18 8.5C17.4477 8.5 17 8.05228 17 7.5V4.75H7V7.5C7 8.05228 6.55228 8.5 6 8.5C5.44772 8.5 5 8.05228 5 7.5V3.75Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5 14.25C5 13.6977 5.44772 13.25 6 13.25H18C18.5523 13.25 19 13.6977 19 14.25V20.625C19 21.1773 18.5523 21.625 18 21.625H6C5.44772 21.625 5 21.1773 5 20.625V14.25ZM7 15.25V19.625H17V15.25H7Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.24687 8.5C3.83296 8.5 3.625 8.79995 3.625 9V15.5H6C6.55228 15.5 7 15.9477 7 16.5C7 17.0523 6.55228 17.5 6 17.5H2.625C2.07272 17.5 1.625 17.0523 1.625 16.5V9C1.625 7.55005 2.87954 6.5 4.24687 6.5H19.7531C21.1205 6.5 22.375 7.55005 22.375 9V16.5C22.375 17.0523 21.9273 17.5 21.375 17.5H18C17.4477 17.5 17 17.0523 17 16.5C17 15.9477 17.4477 15.5 18 15.5H20.375V9C20.375 8.79995 20.167 8.5 19.7531 8.5H4.24687Z"
			fill="currentColor"
		/>
		<path
			d="M17.625 12C18.2463 12 18.75 11.4963 18.75 10.875C18.75 10.2537 18.2463 9.75 17.625 9.75C17.0037 9.75 16.5 10.2537 16.5 10.875C16.5 11.4963 17.0037 12 17.625 12Z"
			fill="currentColor"
		/>
	</svg>
);
