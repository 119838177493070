import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Warning: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 8.75C12.5523 8.75 13 9.19772 13 9.75V13.5C13 14.0523 12.5523 14.5 12 14.5C11.4477 14.5 11 14.0523 11 13.5V9.75C11 9.19772 11.4477 8.75 12 8.75Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.7506 2.33606C11.1302 2.11593 11.5612 2 12 2C12.4388 2 12.8698 2.11593 13.2494 2.33606C13.6282 2.55573 13.9424 2.87138 14.1603 3.25117C14.1608 3.25196 14.1612 3.25275 14.1617 3.25354L22.4098 17.5002C22.6288 17.8796 22.7443 18.3099 22.7447 18.748C22.7451 19.1861 22.6304 19.6166 22.412 19.9964C22.1936 20.3762 21.8793 20.6919 21.5004 20.912C21.1216 21.132 20.6916 21.2487 20.2535 21.2502L20.25 21.2502H3.75L3.74648 21.2502C3.30838 21.2487 2.87838 21.132 2.49956 20.912C2.12074 20.6919 1.80639 20.3762 1.58801 19.9964C1.36964 19.6166 1.25489 19.1861 1.25528 18.748C1.25567 18.3099 1.37117 17.8796 1.59023 17.5002L9.83832 3.25354C9.83877 3.25275 9.83923 3.25196 9.83968 3.25117C10.0576 2.87138 10.3718 2.55573 10.7506 2.33606ZM10.7063 3.75024L11.5717 4.25127L3.32227 18.5002C3.32219 18.5004 3.32234 18.5001 3.32227 18.5002C3.2786 18.576 3.25536 18.6623 3.25528 18.7498C3.2552 18.8374 3.27815 18.9235 3.32183 18.9995C3.3655 19.0754 3.42837 19.1386 3.50414 19.1826C3.57955 19.2264 3.66511 19.2497 3.75231 19.2502H20.2477C20.3349 19.2497 20.4204 19.2264 20.4959 19.1826C20.5716 19.1386 20.6345 19.0754 20.6782 18.9995C20.7218 18.9235 20.7448 18.8374 20.7447 18.7498C20.7446 18.6624 20.7216 18.5765 20.678 18.5007C20.6779 18.5006 20.6781 18.5009 20.678 18.5007L12.4258 4.24694C12.3829 4.17192 12.3209 4.10956 12.2461 4.0662C12.1713 4.02284 12.0864 4 12 4C11.9136 4 11.8287 4.02284 11.7539 4.0662C11.6791 4.10956 11.6171 4.17191 11.5742 4.24693L10.7063 3.75024Z"
			fill="currentColor"
		/>
		<path
			d="M12 18C12.6213 18 13.125 17.4963 13.125 16.875C13.125 16.2537 12.6213 15.75 12 15.75C11.3787 15.75 10.875 16.2537 10.875 16.875C10.875 17.4963 11.3787 18 12 18Z"
			fill="currentColor"
		/>
	</svg>
);
