import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const ExpandChevron: SvgComponent = (props) => (
	<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M0.238138 1.60971L3.20727 5.46959C3.54401 5.90734 4.17186 5.98924 4.60961 5.6525C4.67824 5.59971 4.73973 5.53822 4.79252 5.46959L7.76166 1.60971C8.09839 1.17196 8.0165 0.54411 7.57875 0.207376C7.40394 0.0729083 7.18958 0 6.96904 0H1.03076C0.478477 0 0.0307617 0.447715 0.0307617 1C0.0307617 1.22054 0.10367 1.4349 0.238138 1.60971Z"
			fill="currentColor"
		/>
	</svg>
);
