import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const ImageSquare: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.75 4.75V19.25H19.25V4.75H4.75ZM2.75 4.5C2.75 3.5335 3.5335 2.75 4.5 2.75H19.5C20.4665 2.75 21.25 3.5335 21.25 4.5V19.5C21.25 20.4665 20.4665 21.25 19.5 21.25H4.5C3.5335 21.25 2.75 20.4665 2.75 19.5V4.5Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.0741 9.94633C15.2882 9.8567 15.5179 9.81055 15.75 9.81055C15.9821 9.81055 16.2118 9.8567 16.4259 9.94633C16.6385 10.0354 16.8314 10.1655 16.9936 10.3293C16.9947 10.3304 16.9958 10.3315 16.9969 10.3326L20.9571 14.2928C21.3476 14.6833 21.3476 15.3165 20.9571 15.707C20.5666 16.0975 19.9334 16.0975 19.5429 15.707L15.75 11.9141L11.7436 15.9205C11.5815 16.0843 11.3885 16.2145 11.1759 16.3035C10.9618 16.3931 10.7321 16.4393 10.5 16.4393C10.2679 16.4393 10.0382 16.3931 9.82412 16.3035C9.61148 16.2145 9.41854 16.0843 9.25638 15.9205L7.5 14.1641L4.45711 17.207C4.06658 17.5975 3.43342 17.5975 3.04289 17.207C2.65237 16.8165 2.65237 16.1833 3.04289 15.7928L6.25638 12.5793C6.41854 12.4155 6.61148 12.2854 6.82412 12.1963C7.03818 12.1067 7.26793 12.0605 7.5 12.0605C7.73207 12.0605 7.96182 12.1067 8.17588 12.1963C8.38853 12.2854 8.58147 12.4155 8.74364 12.5793L10.5 14.3357L14.5064 10.3293C14.6686 10.1655 14.8615 10.0354 15.0741 9.94633Z"
			fill="currentColor"
		/>
		<path
			d="M9.375 9.75C9.99632 9.75 10.5 9.24632 10.5 8.625C10.5 8.00368 9.99632 7.5 9.375 7.5C8.75368 7.5 8.25 8.00368 8.25 8.625C8.25 9.24632 8.75368 9.75 9.375 9.75Z"
			fill="currentColor"
		/>
	</svg>
);
