import React, { FC, MouseEventHandler, useMemo } from 'react';

import { cn } from '@/utils';

import cls from './Icon.module.scss';
import { checkIconName, IconName, icons } from './icons';

type IconProps = {
	/** Состояние, при котором отображается стандартный курсор и не обрабатывается onClick */
	disabled?: boolean;
	/** Имя иконки. Соответствует имени файла без расширения */
	name: IconName;
	/** Обработчик события клика по иконке */
	onClick?: MouseEventHandler<SVGSVGElement>;
	/** Размер иконки. Задает одинаковую ширину и высоту. По умолчанию используются настройки самой иконки */
	size?: number | `${string}%`;
	className?: string;
};

const Icon: FC<IconProps> = ({ disabled, name, size = 24, onClick, className }) => {
	const Svg = useMemo(() => {
		const SvgList = icons[name];
		if (!Array.isArray(SvgList)) return SvgList;

		// Предполагается, что список иконок отсортирован от меньшего разрешения к большему. Поэтому в качестве иконки по умолчанию выбирается исполнение с лучшей детализацией.
		const BetterSvg = SvgList[SvgList.length - 1];
		if (typeof size !== 'number') return BetterSvg;

		return SvgList.find((item) => item.size && size <= item.size) || BetterSvg;
	}, [name, size]);

	const width = size || Svg.width;
	const height = size || Svg.height;
	// eslint-disable-next-line no-param-reassign
	onClick = disabled ? undefined : onClick;

	return (
		<Svg
			className={cn(cls.icon, onClick && cls['icon--clickable'], className)}
			focusable="false"
			height={height}
			width={width}
			onClick={onClick}
		/>
	);
};

export { type IconProps, checkIconName, Icon, IconName, icons };
