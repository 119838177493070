/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const CheckCircle: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16.8486 9.05973C17.2298 9.45934 17.2149 10.0923 16.8153 10.4736L11.3121 15.7236C10.9255 16.0924 10.3173 16.0921 9.93099 15.723L7.18412 13.098C6.78483 12.7164 6.77047 12.0834 7.15204 11.6841C7.5336 11.2848 8.16661 11.2705 8.56589 11.652L10.6225 13.6174L15.4347 9.02645C15.8343 8.64522 16.4673 8.66012 16.8486 9.05973Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
			fill="currentColor"
		/>
	</svg>
);
