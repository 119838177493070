import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Question: SvgComponent = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.00001 2.66683C5.05449 2.66683 2.66668 5.05464 2.66668 8.00016C2.66668 10.9457 5.05449 13.3335 8.00001 13.3335C10.9455 13.3335 13.3333 10.9457 13.3333 8.00016C13.3333 5.05464 10.9455 2.66683 8.00001 2.66683ZM1.33334 8.00016C1.33334 4.31826 4.31811 1.3335 8.00001 1.3335C11.6819 1.3335 14.6667 4.31826 14.6667 8.00016C14.6667 11.6821 11.6819 14.6668 8.00001 14.6668C4.31811 14.6668 1.33334 11.6821 1.33334 8.00016Z"
			fill="currentColor"
		/>
		<path
			d="M8 12C8.41421 12 8.75 11.6642 8.75 11.25C8.75 10.8358 8.41421 10.5 8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.07519 4.51745C7.51678 4.33454 8.00269 4.28669 8.47148 4.37993C8.94027 4.47318 9.37088 4.70335 9.70885 5.04132C10.0468 5.3793 10.277 5.80991 10.3702 6.2787C10.4635 6.74748 10.4156 7.23339 10.2327 7.67498C10.0498 8.11657 9.74006 8.494 9.34264 8.75955C9.13256 8.89992 8.90298 9.00569 8.66261 9.07422C8.62577 9.40756 8.34317 9.66683 8.00001 9.66683C7.63182 9.66683 7.33334 9.36835 7.33334 9.00016V8.50016C7.33334 8.32335 7.40358 8.15378 7.52861 8.02876C7.65363 7.90373 7.8232 7.8335 8.00001 7.8335C8.21427 7.8335 8.42372 7.76996 8.60188 7.65092C8.78003 7.53188 8.91888 7.36269 9.00088 7.16474C9.08287 6.96678 9.10433 6.74896 9.06253 6.53882C9.02073 6.32867 8.91755 6.13564 8.76604 5.98413C8.61454 5.83262 8.4215 5.72945 8.21136 5.68765C8.00121 5.64585 7.78339 5.6673 7.58544 5.74929C7.38748 5.83129 7.21829 5.97014 7.09925 6.1483C6.98021 6.32645 6.91668 6.5359 6.91668 6.75016C6.91668 7.11835 6.6182 7.41683 6.25001 7.41683C5.88182 7.41683 5.58334 7.11835 5.58334 6.75016C5.58334 6.27219 5.72508 5.80495 5.99063 5.40754C6.25617 5.01012 6.6336 4.70037 7.07519 4.51745Z"
			fill="currentColor"
		/>
	</svg>
);
