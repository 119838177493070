import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Reset: SvgComponent = (props) => (
	<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14 21C15.8565 21 17.637 20.2625 18.9497 18.9497C20.2625 17.637 21 15.8565 21 14C21 12.1435 20.2625 10.363 18.9497 9.05025C17.637 7.7375 15.8565 7 14 7C12.1435 7 10.363 7.7375 9.05025 9.05025C7.7375 10.363 7 12.1435 7 14C7 15.8565 7.7375 17.637 9.05025 18.9497C10.363 20.2625 12.1435 21 14 21ZM12.8686 11.6314C12.7036 11.472 12.4826 11.3838 12.2531 11.3858C12.0237 11.3878 11.8043 11.4798 11.642 11.642C11.4798 11.8043 11.3878 12.0237 11.3858 12.2531C11.3838 12.4826 11.472 12.7036 11.6314 12.8686L12.7628 14L11.6314 15.1314C11.5478 15.2121 11.4811 15.3086 11.4353 15.4154C11.3894 15.5222 11.3653 15.637 11.3643 15.7531C11.3633 15.8693 11.3854 15.9846 11.4294 16.0921C11.4734 16.1996 11.5384 16.2973 11.6205 16.3795C11.7027 16.4616 11.8004 16.5266 11.9079 16.5706C12.0154 16.6146 12.1307 16.6367 12.2469 16.6357C12.363 16.6347 12.4779 16.6106 12.5846 16.5647C12.6914 16.5189 12.7879 16.4522 12.8686 16.3686L14 15.2372L15.1314 16.3686C15.2964 16.528 15.5174 16.6162 15.7469 16.6142C15.9763 16.6122 16.1957 16.5202 16.358 16.358C16.5202 16.1957 16.6122 15.9763 16.6142 15.7469C16.6162 15.5174 16.528 15.2964 16.3686 15.1314L15.2372 14L16.3686 12.8686C16.528 12.7036 16.6162 12.4826 16.6142 12.2531C16.6122 12.0237 16.5202 11.8043 16.358 11.642C16.1957 11.4798 15.9763 11.3878 15.7469 11.3858C15.5174 11.3838 15.2964 11.472 15.1314 11.6314L14 12.7628L12.8686 11.6314Z"
			fill="currentColor"
		/>
	</svg>
);
