/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Check: SvgComponent = (props) => (
	<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M1 4.25L5.5 8.75L13 1.25" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
	</svg>
);
