import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Search: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_3772_55746)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.59999 4.79985C8.32695 4.79985 7.10606 5.30556 6.20588 6.20573C5.30571 7.10591 4.79999 8.32681 4.79999 9.59985C4.79999 10.8729 5.30571 12.0938 6.20588 12.994C7.10606 13.8941 8.32695 14.3998 9.59999 14.3998C10.873 14.3998 12.0939 13.8941 12.9941 12.994C13.8943 12.0938 14.4 10.8729 14.4 9.59985C14.4 8.32681 13.8943 7.10591 12.9941 6.20573C12.0939 5.30556 10.873 4.79985 9.59999 4.79985ZM2.39999 9.59985C2.39985 8.46669 2.66716 7.3495 3.1802 6.33914C3.69323 5.32877 4.4375 4.45376 5.35247 3.78528C6.26744 3.11679 7.32727 2.6737 8.44577 2.49204C9.56427 2.31039 10.7099 2.3953 11.7893 2.73987C12.8688 3.08443 13.8518 3.67893 14.6582 4.475C15.4646 5.27107 16.0717 6.24625 16.4302 7.3212C16.7887 8.39616 16.8884 9.54056 16.7212 10.6613C16.554 11.7821 16.1246 12.8475 15.468 13.771L21.2484 19.5514C21.467 19.7778 21.5879 20.0809 21.5852 20.3955C21.5825 20.7102 21.4563 21.0111 21.2338 21.2336C21.0113 21.4561 20.7103 21.5823 20.3957 21.5851C20.081 21.5878 19.7779 21.4668 19.5516 21.2482L13.7724 15.469C12.6952 16.235 11.428 16.6896 10.1096 16.7832C8.7912 16.8767 7.47248 16.6056 6.29795 15.9994C5.12343 15.3932 4.13842 14.4755 3.45086 13.3467C2.76331 12.2179 2.39974 10.9216 2.39999 9.59985Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3772_55746">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
