import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const User: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 4C9.23858 4 7 6.23858 7 9C7 11.7614 9.23858 14 12 14C14.7614 14 17 11.7614 17 9C17 6.23858 14.7614 4 12 4ZM5 9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9C19 12.866 15.866 16 12 16C8.13401 16 5 12.866 5 9Z"
			fill="#95A9B9"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.9999 15.9991C10.3322 15.9991 8.69382 16.4382 7.24954 17.2721C5.80527 18.1061 4.60598 19.3055 3.77224 20.7499C3.49615 21.2282 2.88457 21.3922 2.40625 21.1161C1.92793 20.84 1.764 20.2284 2.0401 19.7501C3.04937 18.0016 4.50113 16.5496 6.24946 15.5401C7.99779 14.5306 9.98107 13.9991 11.9999 13.9991C14.0188 13.9991 16.0021 14.5306 17.7504 15.5401C19.4987 16.5496 20.9505 18.0016 21.9597 19.7501C22.2358 20.2284 22.0719 20.84 21.5936 21.1161C21.1153 21.3922 20.5037 21.2282 20.2276 20.7499C19.3939 19.3055 18.1946 18.1061 16.7503 17.2721C15.306 16.4382 13.6677 15.9991 11.9999 15.9991Z"
			fill="#95A9B9"
		/>
	</svg>
);
