import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const ErrorCircleFilled: SvgComponent = (props) => (
	<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.75 20.5C3.75 11.5254 11.0254 4.25 20 4.25C28.9746 4.25 36.25 11.5254 36.25 20.5C36.25 29.4746 28.9746 36.75 20 36.75C11.0254 36.75 3.75 29.4746 3.75 20.5ZM14.6947 25.8077C14.2066 25.3196 14.2066 24.5281 14.6947 24.0399L18.2322 20.5024L14.6947 16.9649C14.2066 16.4768 14.2066 15.6853 14.6947 15.1972C15.1829 14.709 15.9743 14.709 16.4625 15.1972L20 18.7347L23.5385 15.1962C24.0266 14.708 24.8181 14.708 25.3062 15.1962C25.7944 15.6843 25.7944 16.4758 25.3062 16.964L21.7678 20.5024L25.3062 24.0409C25.7944 24.5291 25.7944 25.3205 25.3062 25.8087C24.8181 26.2968 24.0266 26.2968 23.5385 25.8087L20 22.2702L16.4625 25.8077C15.9743 26.2959 15.1829 26.2959 14.6947 25.8077Z"
			fill="currentColor"
		/>
	</svg>
);
