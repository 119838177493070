/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Attention: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_3772_55744)">
			<path
				d="M6.21244 10.1747C6.54296 7.22794 9.03477 5 12 5V5C14.9652 5 17.457 7.22795 17.7876 10.1747L17.9159 11.3185C18.0407 12.4317 18.4843 13.4852 19.1933 14.3525L20.5788 16.0473C20.8512 16.3805 21 16.7976 21 17.2279V17.2279C21 18.2066 20.2066 19 19.2279 19H4.77207C3.79338 19 3 18.2066 3 17.2279V17.2279C3 16.7976 3.1488 16.3805 3.42117 16.0473L4.8067 14.3525C5.51571 13.4852 5.95928 12.4317 6.08414 11.3185L6.21244 10.1747Z"
				stroke="currentColor"
				strokeWidth="2"
			/>
			<path
				d="M15 19C15 20.6569 13.6569 22 12 22C10.3431 22 9 20.6569 9 19"
				stroke="currentColor"
				strokeWidth="2"
			/>
			<path
				d="M14 5V4C14 2.89543 13.1046 2 12 2C10.8954 2 10 2.89543 10 4V5"
				stroke="currentColor"
				strokeWidth="2"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3772_55744">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
