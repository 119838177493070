import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const EyeClosed: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.2889 10.961C18.8264 10.6494 19.5148 10.8325 19.8264 11.37L21.9733 15.0731C22.2849 15.6107 22.1018 16.299 21.5642 16.6107C21.0267 16.9223 20.3384 16.7392 20.0267 16.2016L17.8799 12.4985C17.5682 11.961 17.7514 11.2726 18.2889 10.961Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.261 12.8888C14.8729 12.781 15.4564 13.1897 15.5642 13.8016L16.2298 17.5797C16.3376 18.1916 15.929 18.775 15.3171 18.8828C14.7052 18.9906 14.1217 18.582 14.0139 17.9701L13.3483 14.192C13.2405 13.5801 13.6492 12.9966 14.261 12.8888Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.7291 12.8795C10.341 12.987 10.7499 13.5703 10.6424 14.1822L9.97677 17.9697C9.86922 18.5817 9.28596 18.9906 8.67402 18.883C8.06208 18.7755 7.65318 18.1922 7.76073 17.5803L8.42635 13.7928C8.5339 13.1808 9.11716 12.7719 9.7291 12.8795Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.69963 10.9598C6.23783 11.2702 6.42246 11.9582 6.11201 12.4964L3.96514 16.2183C3.65469 16.7565 2.96672 16.9411 2.42852 16.6306C1.89032 16.3202 1.70569 15.6322 2.01614 15.094L4.16301 11.3722C4.47346 10.834 5.16143 10.6493 5.69963 10.9598Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.29313 8.95933C2.77648 8.56893 3.48479 8.64428 3.87519 9.12763C5.32591 10.9238 7.92282 13.1251 12 13.1251C16.0772 13.1251 18.6741 10.9238 20.1248 9.12763C20.5152 8.64428 21.2235 8.56893 21.7069 8.95933C22.1902 9.34973 22.2656 10.058 21.8752 10.5414C20.1759 12.6453 16.9978 15.3751 12 15.3751C7.00219 15.3751 3.8241 12.6453 2.12482 10.5414C1.73443 10.058 1.80978 9.34973 2.29313 8.95933Z"
			fill="currentColor"
		/>
	</svg>
);
