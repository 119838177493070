import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Submenu: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_3772_55733)">
			<circle cx="12" cy="5" r="2" fill="currentColor" />
			<circle cx="12" cy="12" r="2" fill="currentColor" />
			<circle cx="12" cy="19" r="2" fill="currentColor" />
		</g>
		<defs>
			<clipPath id="clip0_3772_55733">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
