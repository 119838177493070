/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const CloseSmall: SvgComponent = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M8.93999 7.9998L11.8067 5.1398C11.9322 5.01426 12.0027 4.844 12.0027 4.66646C12.0027 4.48893 11.9322 4.31867 11.8067 4.19313C11.6811 4.0676 11.5109 3.99707 11.3333 3.99707C11.1558 3.99707 10.9855 4.0676 10.86 4.19313L7.99999 7.0598L5.13999 4.19313C5.01445 4.0676 4.84419 3.99707 4.66666 3.99707C4.48912 3.99707 4.31886 4.0676 4.19332 4.19313C4.06779 4.31867 3.99726 4.48893 3.99726 4.66646C3.99726 4.844 4.06779 5.01426 4.19332 5.1398L7.05999 7.9998L4.19332 10.8598C4.13084 10.9218 4.08124 10.9955 4.0474 11.0767C4.01355 11.158 3.99612 11.2451 3.99612 11.3331C3.99612 11.4211 4.01355 11.5083 4.0474 11.5895C4.08124 11.6708 4.13084 11.7445 4.19332 11.8065C4.2553 11.8689 4.32903 11.9185 4.41027 11.9524C4.49151 11.9862 4.57865 12.0037 4.66666 12.0037C4.75466 12.0037 4.8418 11.9862 4.92304 11.9524C5.00428 11.9185 5.07801 11.8689 5.13999 11.8065L7.99999 8.9398L10.86 11.8065C10.922 11.8689 10.9957 11.9185 11.0769 11.9524C11.1582 11.9862 11.2453 12.0037 11.3333 12.0037C11.4213 12.0037 11.5085 11.9862 11.5897 11.9524C11.6709 11.9185 11.7447 11.8689 11.8067 11.8065C11.8691 11.7445 11.9187 11.6708 11.9526 11.5895C11.9864 11.5083 12.0039 11.4211 12.0039 11.3331C12.0039 11.2451 11.9864 11.158 11.9526 11.0767C11.9187 10.9955 11.8691 10.9218 11.8067 10.8598L8.93999 7.9998Z"
			fill="currentColor"
		/>
	</svg>
);
