import { useEffect, useState } from 'react';

export const useRootElement = (query: string) => {
	const [node, setNode] = useState<Element>(null);

	useEffect(() => {
		setNode(document.querySelector(query));
	}, []);

	return node;
};
