import { type SvgComponent } from '@/types/modules/svg.types';

import { Add } from './Add';
import { Arrow } from './Arrow';
import { ArrowLeft } from './ArrowLeft';
import { ArrowsOutCardinal } from './ArrowsOutCardinal';
import { ArrowsSquareOut } from './ArrowsSquareOut';
import { Attention } from './Attention';
import { Bold } from './Bold';
import { Calendar } from './Calendar';
import { Check } from './Check';
import { CheckCircle } from './CheckCircle';
import { CheckCircleFilled } from './CheckCircleFilled';
import { Clock } from './Clock';
import { CloseBig } from './CloseBig';
import { CloseSmall } from './CloseSmall';
import { Copy } from './Copy';
import { DotsSixVertical } from './DotsSixVertical';
import { Download } from './Download';
import { DownloadFile } from './DownloadFile';
import { Edit } from './Edit';
import { ErrorCircleFilled } from './ErrorCircleFilled';
import { ExpandChevron } from './ExpandChevron';
import { EyeClosed } from './EyeClosed';
import { EyeOpened } from './EyeOpened';
import { Favorite } from './Favorite';
import { File } from './File';
import { Filter } from './Filter';
import { History } from './History';
import { ImageSquare } from './ImageSquare';
import { Info } from './Info';
import { Italic } from './Italic';
import { Link } from './Link';
import { ListArrowDown } from './ListArrowDown';
import { ListArrowUp } from './ListArrowUp';
import { Lock } from './Lock';
import { Login } from './Login';
import { Logout } from './Logout';
import { NotificationBell } from './NotificationBell';
import { Options } from './Options';
import { OrderedList } from './OrderedList';
import { Printer } from './Printer';
import { PushPin } from './PushPin';
import { Question } from './Question';
import { Refresh } from './Refresh';
import { Reset } from './Reset';
import { Search } from './Search';
import { Settings } from './Settings';
import { Signout } from './Signout';
import { Sort } from './Sort';
import { Submenu } from './Submenu';
import { TableColumn } from './TableColumn';
import { TextAlignLeft } from './TextAlignLeft';
import { Trash } from './Trash';
import { UnorderedList } from './UnorderedList';
import { UploadFile } from './UploadFile';
import { User } from './User';
import { Warning } from './Warning';
import { WarningCircle } from './WarningCircle';

const imports = {
	add: Add,
	arrow: Arrow,
	arrowsOutCardinal: ArrowsOutCardinal,
	arrowsSquareOut: ArrowsSquareOut,
	arrowLeft: ArrowLeft,
	attention: Attention,
	bold: Bold,
	calendar: Calendar,
	check: Check,
	checkCircle: CheckCircle,
	checkCircleFilled: CheckCircleFilled,
	clock: Clock,
	closeBig: CloseBig,
	closeSmall: CloseSmall,
	copy: Copy,
	dotsSixVertical: DotsSixVertical,
	download: Download,
	downloadFile: DownloadFile,
	edit: Edit,
	errorCircleFilled: ErrorCircleFilled,
	expandChevron: ExpandChevron,
	eyeClosed: EyeClosed,
	eyeOpened: EyeOpened,
	favorite: Favorite,
	file: File,
	filter: Filter,
	history: History,
	imageSquare: ImageSquare,
	info: Info,
	italic: Italic,
	link: Link,
	listArrowDown: ListArrowDown,
	listArrowUp: ListArrowUp,
	lock: Lock,
	login: Login,
	logout: Logout,
	notificationBell: NotificationBell,
	options: Options,
	orderedList: OrderedList,
	printer: Printer,
	pushPin: PushPin,
	question: Question,
	refresh: Refresh,
	reset: Reset,
	search: Search,
	settings: Settings,
	sort: Sort,
	submenu: Submenu,
	signout: Signout,
	tableColumn: TableColumn,
	textAlignLeft: TextAlignLeft,
	trash: Trash,
	unorderedList: UnorderedList,
	uploadFile: UploadFile,
	user: User,
	warning: Warning,
	warningCircle: WarningCircle,
};

export type IconName = keyof typeof imports;

type IconMap = {
	[name in IconName]: SvgComponent | SvgComponent[];
};

export const icons: IconMap = imports;

export const checkIconName = (icon?: string | null): icon is IconName => typeof icon === 'string' && icon in icons;

type IconNameMap = {
	[name in IconName]: IconName;
};
export const IconName = (Object.keys(icons) as IconName[]).reduce((result, name) => {
	// eslint-disable-next-line no-param-reassign
	result[name] = name;
	return result;
}, {} as IconNameMap);
