import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const TextAlignLeft: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.75 6.375C2.75 5.82272 3.19772 5.375 3.75 5.375H20.25C20.8023 5.375 21.25 5.82272 21.25 6.375C21.25 6.92728 20.8023 7.375 20.25 7.375H3.75C3.19772 7.375 2.75 6.92728 2.75 6.375Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.75 10.125C2.75 9.57272 3.19772 9.125 3.75 9.125H15.75C16.3023 9.125 16.75 9.57272 16.75 10.125C16.75 10.6773 16.3023 11.125 15.75 11.125H3.75C3.19772 11.125 2.75 10.6773 2.75 10.125Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.75 13.875C2.75 13.3227 3.19772 12.875 3.75 12.875H20.25C20.8023 12.875 21.25 13.3227 21.25 13.875C21.25 14.4273 20.8023 14.875 20.25 14.875H3.75C3.19772 14.875 2.75 14.4273 2.75 13.875Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.75 17.625C2.75 17.0727 3.19772 16.625 3.75 16.625H15.75C16.3023 16.625 16.75 17.0727 16.75 17.625C16.75 18.1773 16.3023 18.625 15.75 18.625H3.75C3.19772 18.625 2.75 18.1773 2.75 17.625Z"
			fill="currentColor"
		/>
	</svg>
);
