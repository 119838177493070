/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const ArrowsOutCardinal: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M14.6532 19.0967L12 21.7498L9.34692 19.0967"
			stroke="#E4E8F0"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M12 15V21.75" stroke="#E4E8F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M9.34692 4.90312L12 2.25L14.6532 4.90312"
			stroke="#E4E8F0"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M12 9V2.25" stroke="#E4E8F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M4.90312 14.6529L2.25 11.9998L4.90312 9.34668"
			stroke="#95A9B9"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M9 12H2.25" stroke="#95A9B9" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path
			d="M19.0969 9.34668L21.75 11.9998L19.0969 14.6529"
			stroke="#E4E8F0"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M15 12H21.75" stroke="#E4E8F0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);
