import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Signout: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.6054 7.35539C15.9959 6.96487 16.6291 6.96487 17.0196 7.35539L20.9571 11.2929C21.3476 11.6834 21.3476 12.3166 20.9571 12.7071L17.0196 16.6446C16.6291 17.0351 15.9959 17.0351 15.6054 16.6446C15.2149 16.2541 15.2149 15.6209 15.6054 15.2304L18.8358 12L15.6054 8.76961C15.2149 8.37908 15.2149 7.74592 15.6054 7.35539Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.75 12C8.75 11.4477 9.19772 11 9.75 11H20.25C20.8023 11 21.25 11.4477 21.25 12C21.25 12.5523 20.8023 13 20.25 13H9.75C9.19772 13 8.75 12.5523 8.75 12Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.75 4.75H9.75C10.3023 4.75 10.75 4.30228 10.75 3.75C10.75 3.19772 10.3023 2.75 9.75 2.75H4.5C4.03587 2.75 3.59075 2.93438 3.26256 3.26256C2.93438 3.59075 2.75 4.03587 2.75 4.5V19.5C2.75 19.9641 2.93437 20.4092 3.26256 20.7374C3.59075 21.0656 4.03587 21.25 4.5 21.25H9.75C10.3023 21.25 10.75 20.8023 10.75 20.25C10.75 19.6977 10.3023 19.25 9.75 19.25H4.75V4.75Z"
			fill="white"
		/>
	</svg>
);
