import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Edit: SvgComponent = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M3.33334 12.0001H6.16001C6.24775 12.0006 6.33472 11.9838 6.41595 11.9506C6.49717 11.9175 6.57105 11.8686 6.63334 11.8068L11.2467 7.18679L13.14 5.33346C13.2025 5.27148 13.2521 5.19775 13.2859 5.11651C13.3198 5.03527 13.3372 4.94813 13.3372 4.86012C13.3372 4.77211 13.3198 4.68498 13.2859 4.60374C13.2521 4.5225 13.2025 4.44876 13.14 4.38679L10.3133 1.52679C10.2514 1.4643 10.1776 1.41471 10.0964 1.38086C10.0152 1.34702 9.92802 1.32959 9.84001 1.32959C9.752 1.32959 9.66487 1.34702 9.58363 1.38086C9.50239 1.41471 9.42865 1.4643 9.36668 1.52679L7.48668 3.41346L2.86001 8.03346C2.79822 8.09575 2.74934 8.16963 2.71616 8.25085C2.68299 8.33208 2.66617 8.41905 2.66668 8.50679V11.3335C2.66668 11.5103 2.73691 11.6798 2.86194 11.8049C2.98696 11.9299 3.15653 12.0001 3.33334 12.0001ZM9.84001 2.94012L11.7267 4.82679L10.78 5.77346L8.89334 3.88679L9.84001 2.94012ZM4.00001 8.78012L7.95334 4.82679L9.84001 6.71346L5.88668 10.6668H4.00001V8.78012ZM14 13.3335H2.00001C1.8232 13.3335 1.65363 13.4037 1.52861 13.5287C1.40358 13.6537 1.33334 13.8233 1.33334 14.0001C1.33334 14.1769 1.40358 14.3465 1.52861 14.4715C1.65363 14.5966 1.8232 14.6668 2.00001 14.6668H14C14.1768 14.6668 14.3464 14.5966 14.4714 14.4715C14.5964 14.3465 14.6667 14.1769 14.6667 14.0001C14.6667 13.8233 14.5964 13.6537 14.4714 13.5287C14.3464 13.4037 14.1768 13.3335 14 13.3335Z"
			fill="currentColor"
		/>
	</svg>
);
