import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const UnorderedList: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect x="8" y="4" width="14" height="2" rx="1" fill="currentColor" />
		<rect x="8" y="11" width="14" height="2" rx="1" fill="currentColor" />
		<rect x="8" y="18" width="14" height="2" rx="1" fill="currentColor" />
		<circle cx="4" cy="5" r="2" fill="currentColor" />
		<circle cx="4" cy="12" r="2" fill="currentColor" />
		<circle cx="4" cy="19" r="2" fill="currentColor" />
	</svg>
);
