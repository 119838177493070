import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const DotsSixVertical: SvgComponent = (props) => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M5.66666 4.6665C6.21894 4.6665 6.66666 4.21879 6.66666 3.6665C6.66666 3.11422 6.21894 2.6665 5.66666 2.6665C5.11437 2.6665 4.66666 3.11422 4.66666 3.6665C4.66666 4.21879 5.11437 4.6665 5.66666 4.6665Z"
			fill="currentColor"
		/>
		<path
			d="M10.1667 4.6665C10.7189 4.6665 11.1667 4.21879 11.1667 3.6665C11.1667 3.11422 10.7189 2.6665 10.1667 2.6665C9.61437 2.6665 9.16666 3.11422 9.16666 3.6665C9.16666 4.21879 9.61437 4.6665 10.1667 4.6665Z"
			fill="currentColor"
		/>
		<path
			d="M5.66666 8.9165C6.21894 8.9165 6.66666 8.46879 6.66666 7.9165C6.66666 7.36422 6.21894 6.9165 5.66666 6.9165C5.11437 6.9165 4.66666 7.36422 4.66666 7.9165C4.66666 8.46879 5.11437 8.9165 5.66666 8.9165Z"
			fill="currentColor"
		/>
		<path
			d="M10.1667 8.9165C10.7189 8.9165 11.1667 8.46879 11.1667 7.9165C11.1667 7.36422 10.7189 6.9165 10.1667 6.9165C9.61437 6.9165 9.16666 7.36422 9.16666 7.9165C9.16666 8.46879 9.61437 8.9165 10.1667 8.9165Z"
			fill="currentColor"
		/>
		<path
			d="M5.66666 13.1665C6.21894 13.1665 6.66666 12.7188 6.66666 12.1665C6.66666 11.6142 6.21894 11.1665 5.66666 11.1665C5.11437 11.1665 4.66666 11.6142 4.66666 12.1665C4.66666 12.7188 5.11437 13.1665 5.66666 13.1665Z"
			fill="currentColor"
		/>
		<path
			d="M10.1667 13.1665C10.7189 13.1665 11.1667 12.7188 11.1667 12.1665C11.1667 11.6142 10.7189 11.1665 10.1667 11.1665C9.61437 11.1665 9.16666 11.6142 9.16666 12.1665C9.16666 12.7188 9.61437 13.1665 10.1667 13.1665Z"
			fill="currentColor"
		/>
	</svg>
);
