/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const CheckCircleFilled: SvgComponent = (props) => (
	<svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M19.9987 3.83594C10.794 3.83594 3.33203 11.2979 3.33203 20.5026C3.33203 29.7073 10.794 37.1693 19.9987 37.1693C29.2034 37.1693 36.6654 29.7073 36.6654 20.5026C36.6654 11.2979 29.2034 3.83594 19.9987 3.83594ZM28.0242 17.9585C28.6902 17.3231 28.715 16.2682 28.0796 15.6022C27.4442 14.9361 26.3893 14.9113 25.7232 15.5467L17.7028 23.1982L14.2752 19.9227C13.6097 19.2867 12.5547 19.3107 11.9188 19.9761C11.2828 20.6416 11.3068 21.6966 11.9722 22.3325L16.5503 26.7075C17.1942 27.3228 18.2079 27.3232 18.8523 26.7085L28.0242 17.9585Z"
			fill="currentColor"
		/>
	</svg>
);
