/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const CloseBig: SvgComponent = (props) => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M25 7L7 25" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		<path d="M25 25L7 7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
	</svg>
);
