import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const PushPin: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.95711 15.0429C9.34763 15.4334 9.34763 16.0666 8.95711 16.4571L5.20711 20.2071C4.81658 20.5976 4.18342 20.5976 3.79289 20.2071C3.40237 19.8166 3.40237 19.1834 3.79289 18.7929L7.54289 15.0429C7.93342 14.6524 8.56658 14.6524 8.95711 15.0429Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.3241 1.69633C14.5382 1.6067 14.7679 1.56055 15 1.56055C15.2321 1.56055 15.4618 1.6067 15.6759 1.69633C15.8885 1.78536 16.0814 1.91552 16.2436 2.07931C16.2447 2.08041 16.2458 2.08151 16.2469 2.08262L21.9173 7.75304C21.9184 7.75411 21.9195 7.75518 21.9205 7.75625C22.0844 7.91843 22.2145 8.11138 22.3036 8.32404C22.3932 8.5381 22.4394 8.76785 22.4394 8.99992C22.4394 9.23199 22.3932 9.46174 22.3036 9.6758C22.2145 9.88846 22.0844 10.0814 21.9205 10.2436C21.9195 10.2447 21.9184 10.2457 21.9173 10.2468L16.553 15.6111C17.3671 18.0707 15.9655 20.3442 15.2255 21.3221C15.0745 21.5223 14.8822 21.6876 14.6616 21.807C14.4401 21.9269 14.1954 21.9975 13.9441 22.0141C13.6928 22.0308 13.4409 21.9929 13.2056 21.9033C12.9719 21.8142 12.76 21.6762 12.5842 21.4984L2.48344 11.3976C2.48285 11.397 2.48225 11.3964 2.48165 11.3958C2.30787 11.223 2.17231 11.0156 2.08376 10.7871C1.99491 10.5578 1.95538 10.3123 1.96777 10.0667C1.98015 9.82107 2.04416 9.58082 2.15563 9.36161C2.2671 9.1424 2.42352 8.94914 2.61469 8.79443L2.61523 8.794C4.21957 7.49756 5.81225 7.20153 7.02313 7.2556C7.56501 7.2798 8.02292 7.37335 8.36559 7.47012L13.7564 2.07931C13.9186 1.91552 14.1115 1.78536 14.3241 1.69633ZM8.17744 9.51917C8.17744 9.51917 8.17745 9.51917 8.17745 9.51918L8.17761 9.51926L8.17834 9.51962L8.17997 9.52043L8.18255 9.52171L8.18507 9.52293C8.18533 9.52306 8.18503 9.52291 8.18419 9.52252C8.18419 9.52252 8.18418 9.52251 8.18418 9.52251C8.1833 9.5221 8.18184 9.52142 8.1798 9.52049C8.17177 9.51682 8.15488 9.5093 8.12972 9.49902C8.07927 9.4784 7.99639 9.44704 7.88556 9.41344C7.66293 9.34593 7.33453 9.2715 6.93391 9.25361C6.19943 9.22081 5.18887 9.37459 4.0934 10.1791L13.7986 19.8843C14.5159 18.8564 15.2172 17.289 14.4814 15.8237C14.2879 15.4385 14.3631 14.9726 14.6679 14.6678L20.3358 8.99992L15 3.66413L9.33211 9.33202C9.0294 9.63474 8.56787 9.71098 8.18419 9.52252M8.17744 9.51917C8.1775 9.5192 8.17755 9.51923 8.17761 9.51926L8.17744 9.51917Z"
			fill="currentColor"
		/>
	</svg>
);
