/* eslint-disable react/react-in-jsx-scope */
import React from 'react';

import { SvgComponent } from '@/types/modules/svg.types';

export const Arrow: SvgComponent = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clipPath="url(#clip0_3772_55739)">
			<path
				d="M12.7102 11.9997L9.17018 8.4597C8.98393 8.27234 8.87939 8.01889 8.87939 7.7547C8.87939 7.49052 8.98393 7.23707 9.17018 7.0497C9.26314 6.95598 9.37374 6.88158 9.4956 6.83081C9.61746 6.78004 9.74817 6.75391 9.88018 6.75391C10.0122 6.75391 10.1429 6.78004 10.2648 6.83081C10.3866 6.88158 10.4972 6.95598 10.5902 7.0497L14.8302 11.2897C14.9239 11.3827 14.9983 11.4933 15.0491 11.6151C15.0998 11.737 15.126 11.8677 15.126 11.9997C15.126 12.1317 15.0998 12.2624 15.0491 12.3843C14.9983 12.5061 14.9239 12.6167 14.8302 12.7097L10.5902 16.9997C10.4967 17.0924 10.3859 17.1657 10.2641 17.2155C10.1422 17.2652 10.0118 17.2905 9.88018 17.2897C9.74857 17.2905 9.61811 17.2652 9.49627 17.2155C9.37443 17.1657 9.26362 17.0924 9.17018 16.9997C8.98393 16.8123 8.87939 16.5589 8.87939 16.2947C8.87939 16.0305 8.98393 15.7771 9.17018 15.5897L12.7102 11.9997Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3772_55739">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
